import React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import AddApplicantVerification from '../components/Verification/Applicant/AddApplicantForm'
import ApplicantVerification from '../components/Verification/Applicant/Dashboard'
import ConsumptiveStatementDashboard from '../components/ConsumptiveStatement/Dashboard'
import DetailApplicantInformation from '../components/Verification/Applicant/DetailApplicant'
import UsageReportDashboard from '../components/UsageReport/Dashboard'
import Footer from '../components/Footer'
import Slik from '../components/Slik/Dashboard'
import Pefindo from '../components/Pefindo/Dashboard'
import StatementDashboard from '../components/Statement/Dashboard'
import StatementDetailDashboard from '../components/SharedStatement/StatementDetailDashboard'
import StructureNav from '../components/Structure/Nav'
import DashboardProfile from '../components/Profile'
import AccountInfo from '../components/Profile/AccountInfo'
import ChangePassword from '../components/Profile/ChangePassword'
import TwoFactorAuthentication from '../components/Profile/TwoFactorAuthentication'
import TrailDashboard from '../components/Trail/Dashboard'
import Error404 from './error/Error404'

import { SocketProvider } from '../contexts/socket'
import { StatementProvider } from '../contexts/statement'

import './App.css'

function App () {
    return (
        <>
            <StructureNav />
            <div className='pt-4' style={{ background: '#f9f9fb', minHeight: '100vh' }}>
                <StatementProvider>
                    <Container style={{ marginBottom: '50px' }}>
                        <Routes>
                            <Route element={<SocketProvider><Outlet /></SocketProvider>}>
                                <Route exact path="/" element={<Navigate to="/statement" />} />
                                <Route exact path="/statement" element={
                                    <StatementDashboard />
                                } />
                                <Route path="/statement/detail/:uuid" element={
                                    <StatementDetailDashboard type='sme' />
                                } />
                                <Route exact path="/consumptive-statement" element={
                                    <ConsumptiveStatementDashboard />
                                } />
                                <Route path="/consumptive-statement/detail/:uuid" element={
                                    <StatementDetailDashboard type='consumptive' />
                                } />
                                <Route path="/slik/*" element={<Slik />} />
                                <Route path="/pefindo/*" element={<Pefindo />} />
                                <Route path="/applicant-verification/*" element={<ApplicantVerification />} />
                                <Route path="/applicant-verification/new" element={<AddApplicantVerification />} />
                                <Route path="/applicant-verification/information/:uuid" element={<DetailApplicantInformation />} />
                                <Route path="/usage-report/*" element={<UsageReportDashboard />} />
                                <Route path="/trail/*" element={<TrailDashboard />} />
                                <Route path="/profile" element={<DashboardProfile />}>
                                    <Route index element={<Navigate to="account-info" />} />
                                    <Route path="account-info" element={<AccountInfo />} />
                                    <Route path="change-password" element={<ChangePassword />} />
                                    <Route path="two-factor-authentication" element={<TwoFactorAuthentication />} />
                                </Route>
                                <Route path="*" element={<Error404 />} />
                            </Route>
                        </Routes>
                    </Container>
                </StatementProvider>
            </div>
            <Footer />
        </>
    )
}

export default App
