import Axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../../contexts/auth'
import { useNavigate, Link } from 'react-router-dom'
import {
    Navbar, Nav, Container, NavDropdown
} from 'react-bootstrap'
import { GetAuthUserMenu } from '../../utils/api'
import { useAnalyticsPushEvent } from '../../analytics'
import CustomModal from '../Shared/CustomModal'
import './Nav.css'

function App () {
    const navigate = useNavigate()
    const pushEvent = useAnalyticsPushEvent()
    const [partnerName, setPartnerName] = useState('yes')
    const [menus, setMenus] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [labelRedirect, setLabelRedirect] = useState('')
    const [modalProperties, setModalProperties] = useState({
        title: '',
        body: '',
        type: ''
    })
    const { handleLogout, user } = useContext(AuthContext)
    const path = window.location.pathname || ''

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const { data: responseData } = await Axios.get(GetAuthUserMenu())
                setMenus(responseData?.data)
            } catch (error) {
                console.error('Error fetching menu:', error)
            }
        }
        fetchMenu()

        const rawPartner = localStorage.getItem('partner')
        if (rawPartner) {
            try {
                const partner = JSON.parse(rawPartner)
                if (partner) {
                    setPartnerName(partner.name)
                }
            } catch (err) {
                setPartnerName('')
            }
        }
    }, [])

    const directTo = (title) => {
        pushEvent('Navigate to ' + title)
    }

    const setModalRedirect = (type) => {
        switch (type) {
        case 'redirect-change-password':
            setOpenModal(true)
            setModalProperties({
                title: 'Pembaharuan Password Diperlukan',
                body: 'Password Anda telah kadaluarsa dan perlu diubah.',
                type
            })
            setLabelRedirect('Arahkan ke Halaman Ubah Password')
            break
        case 'redirect-update-2fa':
            setOpenModal(true)
            setModalProperties({
                title: 'Pembaharuan Keamanan Diperlukan',
                body: 'Silakan tinjau dan perbarui pengaturan Two-Factor Authentication (2FA) Anda untuk memastikan perlindungan akun Anda tetap terjaga. Ini akan membantu melindungi dari akses yang tidak sah.',
                type
            })
            setLabelRedirect('Arahkan ke Pengaturan Pembaruan 2FA')
            break
        default:
            break
        }
    }

    const redirectToProfilePage = () => {
        if (modalProperties.type === 'redirect-change-password') {
            navigate('/profile/change-password')
        } else if (modalProperties.type === 'redirect-update-2fa') {
            navigate('/profile/two-factor-authentication')
        }
        setOpenModal(false)
    }

    useEffect(() => {
        if (!path.includes('/profile')) {
            if (user && user.passwordExpired) {
                setModalRedirect('redirect-change-password')
            } else if (user && user.twoFactorAuthentication === 'inactive') {
                setModalRedirect('redirect-update-2fa')
            }
        }
    }, [user])

    return (
        <Navbar variant="light" expand="lg" className="shadow">
            <Container>
                <Navbar.Brand href="/" className="d-none d-md-block">
                    <img
                        alt="Logo"
                        src="/img/logo.png"
                        // width="100"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Brand href="/" className="d-block d-md-none">
                    <img
                        alt="Logo"
                        src="/img/logo.png"
                        // width="100"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {
                        menus?.map((menu, index) =>
                            (menu?.url && menu?.title) && (
                                <Nav key={index}>
                                    <Nav.Link as={Link} to={menu.url} onClick={() => directTo(menu.title)}
                                        className={{
                                            'fw-bold border-primary border-bottom border-3': window.location.pathname?.startsWith(menu.url)
                                        }}
                                        active={menu && window.location.pathname?.startsWith(menu.url)}>
                                        {menu.title}</Nav.Link>
                                </Nav>
                            ))
                    }
                    <Nav className="ms-auto">
                        <NavDropdown title={<span className="text-primary">{partnerName || ''}</span>}
                            id="basic-nav-dropdown"
                            className="text-primary"
                            align={'end'}>
                            <NavDropdown.Item onClick={() => navigate('/profile')}>Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout}>Keluar</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <CustomModal
                show={openModal}
                title={modalProperties.title}
                keyboard={false}
                handleClose={() => setOpenModal(false)}
                primaryButtonText={labelRedirect}
                primaryButtonVariant={'primary'}
                primaryButtonAction={() => redirectToProfilePage()}
            >
                {modalProperties.body}
            </CustomModal>
        </Navbar >
    )
}

export default App
